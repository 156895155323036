import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routers = [
  {
    path: '/',
    redirect: '/product',
    component: () => import('@/pages/index'),
    children: [
      // {
      //   path: '/home',
      //   name: '首页',
      //   component: () => import('@/pages/home/index'),
      //   meta: {
      //     title: '首页',
      //     icon: ''
      //   }
      // },
      {
        path: '/userCenter',
        name: '个人中心',
        component: () => import('@/pages/userCenter/index'),
        meta: {
          title: '个人中心',
          icon: ''
        }
      },
      {
        path: '/product',
        name: '产品介绍',
        component: () => import('@/pages/product/index'),
        meta: {
          title: '产品介绍',
          icon: ''
        }
      },
      {
        path: '/pay',
        name: '支付',
        component: () => import('@/pages/pay/index'),
        meta: {
          title: '支付',
          icon: ''
        }
      },
      {
        path: '/tools',
        name: '工具',
        component: () => import('@/pages/tools/index'),
        meta: {
          title: '工具',
          icon: ''
        }
      },
      {
        path: '/buySearch',
        name: '购买search次数',
        component: () => import('@/pages/pay/search'),
        meta: {
          title: '购买search次数',
          icon: ''
        }
      },
      {
        path: '/buyAccount',
        name: '购买推特账号',
        component: () => import('@/pages/account/buy/index'),
        meta: {
          title: '购买推特账号',
          icon: ''
        }
      },
      {
        path: '/importAccount',
        name: '导入推特账号',
        component: () => import('@/pages/account/import/index'),
        meta: {
          title: '导入推特账号',
          icon: ''
        }
      },
      {
        path: '/bind',
        name: '绑定钱包地址',
        component: () => import('@/pages/account/bind/index'),
        meta: {
          title: '绑定钱包地址',
          icon: ''
        }
      },
      {
        path: '/back',
        name: '后台自用',
        component: () => import('@/pages/back/index'),
        meta: {
          title: '后台自用',
          icon: ''
        }
      },
      {
        path: '/twitterAccount',
        name: '购买账号管理',
        component: () => import('@/pages/tools/twitterAccount'),
        meta: {
          title: '推特账号管理',
          icon: ''
        }
      },
      {
        path: '/batchTools',
        name: '批量工具',
        component: () => import('@/pages/tools/indexBatch'),
        meta: {
          title: '批量工具',
          icon: ''
        }
      },
      {
        path: '/shadow',
        name: '批量影子校验',
        component: () => import('@/pages/tools/shadowbanned'),
        meta: {
          title: '批量影子校验',
          icon: ''
        }
      },
      {
        path: '/web3Tools',
        name: 'web3Tools',
        meta: {
          title: 'web3Tools',
          icon: ''
        },
        component: () => null,
        // 在路由的 beforeEnter 钩子中使用 window.open 打开新页面
        beforeEnter: (to, from, next) => {
          window.open('https://utools.me');
          next(false); // 阻止当前路由跳转
        }
      },
      {
        path: '/home',
        name: '产品介绍',
        component: () => import('@/pages/product/index'),
        meta: {
          title: '产品介绍',
          icon: ''
        }
      },
      {
        path: '/twitter/callback',
        name: 'twitterCallback',
        component: () => import('@/pages/twitter/index'),
        meta: {
          title: 'twitterCallback',
          icon: ''
        }
      }
    ]
  }
]


const router = new VueRouter({
  mode: 'history',
  routes: routers
})

export default router
